.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, rgba(0, 25, 43, 1) 0%, rgba(0, 17, 31, 1) 100%);
}

/* Loader */
.loader {
  position: relative;
  width: 48px;
  height: 48px;
  background: #00ffd8;
  transform: rotateX(65deg) rotate(45deg);
  color: #fff;
  animation: layers1 1s linear infinite alternate;
}

.loader:after {
  content: '';
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.7);
  animation: layerTr 1s linear infinite alternate;
}

@keyframes layers1 {
  0% {
    box-shadow: 0px 0px 0 0px
  }

  90%,
  100% {
    box-shadow: 20px 20px 0 -4px
  }
}

@keyframes layerTr {
  0% {
    transform: translate(0, 0) scale(1)
  }

  100% {
    transform: translate(-25px, -25px) scale(1)
  }
}