.Projects {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    user-select: none;
}

.projects-first {
    background: linear-gradient(0deg, rgba(28, 45, 64, 1) 0%, rgba(21, 34, 48, 1) 100%);
    width: 100vw;
    height: 100vh;
}

.projects-first-inner {
    padding: 15vh 10vw;
}

.projects-title {
    color: white;
    font-family: f-black;
    font-size: 42px;
    margin: 0;
    animation: appear 2s forwards;
}

.projects-subtitle {
    color: white;
    font-family: f-bold;
    font-size: 32px;
    margin: 0;
    animation: appear 2s forwards;
}

.projects-small-sep {
    background-color: #00ffd8;
    width: 7vw;
    height: 4px;
    margin: 20px 0px;
    opacity: 0;
    animation: sepAppear 1s forwards;
}

.projects-p {
    font-family: f-light-it;
    color: white;
    font-size: 20px;
}

.projects-p-color {
    font-family: f-black-it;
    color: #00ffd8;
}


@keyframes sepAppear {
    from {
        margin-left: 100px;
        opacity: 0;
    }

    to {
        margin-left: 0px;
        opacity: 1;
    }
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 25px;
    margin-top: 20px;
}

.projects-widget-tools {
    background-color: rgba(211, 211, 211, 0.1);
    padding: 15px 20px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    opacity: 0;
}

.projects-widget-tools-icon {
    color: white;
    font-size: 34px;
    margin-bottom: 10px;
}

.projects-widget-tools-title {
    color: white;
    font-family: f-bold;
    font-size: 22px;
    margin: 0;
}

#photoshop {
    animation: appear 2s forwards .5s;
}

#react {
    animation: appear 2s forwards 1s;
}

#node {
    animation: appear 1s forwards 1.5s;
}

#firebase {
    animation: appear 1s forwards 2s;
}

#gcp {
    animation: appear 1s forwards 2.5s;
}

.photoshop {
    color: #001E36;
}

.react {
    color: #61DBFB;
}

.node {
    color: #68a063;
}

.firebase {
    color: #FFCB2B;
}

.gcp {
    color: #DB4437;
}