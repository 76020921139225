.Offline {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(0deg, rgba(0, 25, 43, 1) 0%, rgba(0, 17, 31, 1) 100%);
    user-select: none;
    overflow-y: hidden;
    overflow-x: hidden;
}

.offline-title {
    color: white;
    font-size: 52px;
    font-family: f-black;
    text-align: center;
}

.offline-subtitle {
    color: white;
    margin: 20px 0px 0px 0px;
    font-size: 22px;
    font-family: f-light;
    text-align: center;
}

.offline-email {
    color: white;
    font-size: 16px;
    background-color: rgba(211, 211, 211, 0.1);
    padding: 15px 20px;
    border-radius: 3px;
    font-family: f-light;
    user-select: text;
    letter-spacing: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
}

.offline-copied {
    color: #00ffd8;
    font-size: 16px;
    padding: 5px 20px;
    font-family: f-light;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
}

.offline-copied-icon {
    margin-left: 10px;
}

.offline-icon {
    margin-left: 5px;
    transition: .2s;
}

.offline-icon:hover {
    cursor: pointer;
    color: #00ffd8;
}

.offline-img {
    width: 20vw;
    animation: animateImg 2s infinite linear;
}

@keyframes animateImg {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(30deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@media only screen and (max-width: 600px) {

    .Offline {
        justify-content: flex-start;
        padding-top: 10vh;
    }

    .offline-img {
        width: 50vw;
    }

    .offline-title {
        font-size: 42px;
    }

    .offline-subtitle {
        font-size: 16px;
        padding: 0px 40px;
    }

    .offline-email {
        font-size: 14px;
    }
}