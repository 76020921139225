body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Font imports */

@font-face {
  font-family: f-reg;
  src: url('../src/Ressources/Font/Lato/Lato-Regular.ttf');
}

@font-face {
  font-family: f-thin;
  src: url('../src/Ressources/Font/Lato/Lato-Thin.ttf');
}

@font-face {
  font-family: f-light;
  src: url('../src/Ressources/Font/Lato/Lato-Light.ttf');
}

@font-face {
  font-family: f-light-it;
  src: url('../src/Ressources/Font/Lato/Lato-LightItalic.ttf');
}

@font-face {
  font-family: f-bold;
  src: url('../src/Ressources/Font/Lato/Lato-Bold.ttf');
}

@font-face {
  font-family: f-black;
  src: url('../src/Ressources/Font/Lato/Lato-Black.ttf');
}

@font-face {
  font-family: f-black-it;
  src: url('../src/Ressources/Font/Lato/Lato-BlackItalic.ttf');
}