.Error {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, rgba(0, 25, 43, 1) 0%, rgba(0, 17, 31, 1) 100%);
}

.error-container {
    display: flex;
    flex-direction: column;
    padding-left: 5vw;
    user-select: none;
    width: 40vw;
}


.error-404 {
    font-size: f-black;
    color: #00ffd8;
    margin: 10px 0px;
    font-size: 62px;
}

.error-title {
    font-size: f-black;
    color: white;
    margin: 0;
}

.error-p {
    font-size: f-light;
    color: white;
    font-size: 18px;
}

.back {
    background-color: rgba(211, 211, 211, 0.1);
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    font-family: f-black;
    margin: 0px 10px;
}