.Home {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.img1 {
    width: 60vw;
    max-width: 450px;
    z-index: 999;
    opacity: 0;
    animation: appear 2s forwards;
}


@keyframes appear {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.img2 {
    max-width: 200px;
    margin-top: 20px;
    opacity: 0;
    animation: img2Appear 2s forwards;
    transform: rotate(5deg);
}

.background {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    opacity: .2;
    position: absolute;

}

@keyframes img2Appear {
    from {
        padding-left: 300px;
        padding-top: 100px;
        opacity: 0;
    }

    to {
        padding-left: 0px;
        padding-top: 0px;
        opacity: 1;
    }
}

.home-first,
.home-second,
.home-third {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    display: flex;
    scroll-snap-align: start;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, rgba(0, 25, 43, 1) 0%, rgba(0, 17, 31, 1) 100%);
}

.home-subtitle {
    font-family: f-thin;
    color: #00ffd8;
    margin: 0;
    text-align: center;
    z-index: 999;
    opacity: 0;
    animation: appear 3s forwards 2s;
}

.home-title {
    font-family: f-black;
    color: white;
    font-size: 44px;
    text-align: center;
    z-index: 999;
    opacity: 0;
    animation: appear 3s forwards 1s;
}

.home-second {
    background: none;
    background-color: #152230;
    flex-direction: row;
    box-shadow: inset 0px 21px 8px -10px rgb(20, 33, 46);
}

.home-second-inner-left,
.home-second-inner-right {
    flex-direction: column;
    height: 90vh;
    padding: 0px 50px 0px 5vw;
}

.home-second-inner-right {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 150px;
}

.home-second-small-sep {
    background-color: #00ffd8;
    width: 150px;
    height: 5px;
    margin-bottom: 50px;
}

.home-second-sep {
    background-color: rgb(200, 200, 200, .3);
    width: 300px;
    height: 1px;
    margin: 40px 0px;
}

.home-second-title {
    font-family: f-black;
    color: white;
    font-size: 44px;
}

.home-second-large-title {
    font-family: f-black;
    color: white;
    font-size: 54px;
    margin: 0;
}

.home-second-large-subtitle {
    font-family: f-thin;
    line-height: 150%;
    color: white;
    font-size: 20px;
    margin: 10px 0px 0px 0px;
}

.home-second-large-subtitle-color {
    color: #00ffd8;
    font-family: f-bold;
}

.home-second-widget-container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.home-second-widget {
    background-color: rgba(211, 211, 211, 0.1);
    padding: 15px 20px;
    border-radius: 3px;
    user-select: none;
    margin-right: 20px;
}

.home-second-right-button {
    border: none;
    background-color: white;
    font-size: 16px;
    font-family: f-bold;
    padding: 10px 15px;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    transition: .2s;
}

.home-second-right-button:hover {
    background-color: #00ffd8;
}

.home-second-widget-title {
    font-family: f-bold;
    color: white;
    font-size: 16px;
}

#about {
    opacity: 0;
    animation: appearLeft 2s forwards 1s;
}

#projects {
    opacity: 0;
    animation: appearLeft 2s forwards 2s;
}

@keyframes appearLeft {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.home-second-subtitle {
    font-family: f-bold;
    color: white;
    font-size: 16px;
}

.home-second-p {
    font-family: f-light;
    color: white;
    width: 25vw;
    line-height: 160%;
}

.home-second-button {
    border: none;
    background-color: #00ffd8;
    font-family: f-bold;
    padding: 5px 10px;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.home-second-button-icon {
    margin-left: 5px;
    font-size: 20px;
    transition: .2s;
}

.home-second-button:hover .home-second-button-icon {
    margin-left: 15px;
}

.home-third {
    flex-direction: column;
    height: auto;
    padding: 50px;
}

.home-third-icons {
    display: flex;
    flex-direction: row;
}

.home-third-icon-container {
    background-color: white;
    padding: 10px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 7px;
    transition: .2s;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.home-third-icon-container:hover {
    cursor: pointer;
    background-color: #00ffd8;
    color: white;
}

.home-third-icon {
    font-size: 22px;
}

.home-third-title {
    color: white;
    font-family: f-thin;
    font-size: 18px;
    margin: 20px 0px 0px 0px;
}

.home-third-separator {
    width: 60vw;
    height: 1px;
    margin: 20px 0px;
    background-color: rgba(211, 211, 211, 0.2);
}

.home-third-copyright {
    color: white;
    font-family: f-light;
    font-size: 14px;
}