.Contact {
    width: 100vw;
    min-height: 100vh;
    position: relative;
    padding-bottom: 40vh;
}

.contact-background {
    width: 100vw;
    height: 55vh;
    background-color: #152230;
    position: absolute;
}

.contact-img {
    width: 250px;
    position: absolute;
    right: 25vw;
    top: 15vh;
    transform: rotate(15deg);
}

.contact-text {
    position: absolute;
    padding: 20vh 0vw 0vw 10vw;
}

.contact-title {
    color: white;
    font-family: f-black;
    font-size: 52px;
    margin: 0;
}

.contact-p {
    color: white;
    font-family: f-light;
    font-size: 20px;
}

.contact-container {
    position: absolute;
    top: 45vh;
    display: flex;
    flex-direction: row;
    width: 80vw;
    justify-content: center;
}

.contact-container-left {
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    padding: 20px;
    padding-bottom: 30px;
    margin-right: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.contact-container-right {
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    padding: 20px 40px;
    padding-bottom: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 20px;
}

.contact-container-left-title {
    margin: 10px 0px;
    font-family: f-black;
    color: #152230;
}

.contact-container-column {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
}

.contact-input-label {
    font-family: f-bold;
    color: #152230;
    margin-bottom: 10px;
}

.contact-input {
    border: none;
    background-color: #15223005;
    color: #152230;
    outline: none;
    padding: 10px 15px;
    font-family: f-reg;
    border-radius: 2px;
    transition: all .2s ease;
    width: 40vw;
    box-shadow: inset 0 0 10px rgba(21, 34, 48, 0.05);
}

.contact-textarea {
    border: none;
    background-color: #15223005;
    color: #152230;
    outline: none;
    padding: 10px 15px;
    font-family: f-reg;
    border-radius: 2px;
    transition: all .2s ease;
    width: 40vw;
    box-shadow: inset 0 0 10px rgba(21, 34, 48, 0.05);
    resize: none;
    height: 200px;
}

.contact-submit-button {
    border: none;
    background-color: #152230;
    color: white;
    font-family: f-bold;
    margin-top: 20px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    padding: 10px 20px;
    transition: .2s;
}

.contact-submit-button:hover {
    background-color: #18487c;
    cursor: pointer;
}

.contact-container-widget {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
}


.contact-container-widget-title {
    font-family: f-light;
    color: #152230;
    margin-left: 10px;
    font-size: 16px;
    margin: 0 20px;
}

.contact-container-widget-sep {
    width: 95%;
    height: 1px;
    background-color: #15223015;
}

.contact-container-widget-icon-outer {
    background-color: #152230;
    border-radius: 3px;
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-container-widget-icon {
    color: white;
    font-size: 14px;
}

.contact-container-left-grid {
    display: inline-flex;
}

.contact-container-left-widget {
    border: 1px solid #152230;
    padding: 15px 20px;
    border-radius: 2px;
    margin-right: 10px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    transition: .2s;
    color: #152230;
}

.contact-container-left-widget:hover {
    cursor: pointer;
    background-color: #152230;
    color: white;
}



.contact-container-widget-icon {}