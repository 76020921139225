.Header {
    width: 100vw;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: flex-end;
    z-index: 999 ;
    user-select: none;
}

ul {
    display: flex;
    flex-direction: row;
    padding-right: 10vw;
}

li {
    list-style-type: none;
    font-family: f-reg;
    color: white;
    font-size: 20px;
    margin: 10px 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s;
}

li:hover {
    opacity: .5;
}

.title {
    font-family: f-black;
    color: white;
    position: absolute;
    left: 10vw;
    top: 20px;
}

.title .title-light {
    font-family: f-thin;
}

li.active {
    border-bottom: 1px solid white;
}

li.active:hover {
    opacity: 1;
    cursor: auto;
}

li .icon {
    margin: 0 10px 0px 0px;
}

.quickguides {
    background-color: rgba(211, 211, 211, 0.1);
    padding: 15px 20px;
    border-radius: 3px;
}

.arrowDown {
    width: 100vw;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    padding: 3vh 0px;
}

.arrow {
    color: white;
    font-size: 32px;
    animation: bounce 2s infinite;
}

@keyframes bounce {
    0% {
        margin-bottom: 20px;
    }

    50% {
        margin-bottom: 0px;
    }

    100% {
        margin-bottom: 20px;
    }
}